import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store' 
import axios from 'axios';
import VueAxios from 'vue-axios';
import $ from 'jquery'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap"   
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons"; 
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

// import "./assets/style/main.css"
// import "./assets/style/layout.css"
// import "./assets/style/global.css"
// import Multiselect from '@vueform/multiselect'
 
import "./assets/dist/css/plugins.css"
import "./assets/dist/css/style.css"  
import "./assets/dist/css/root.css"  
import "./assets/dist/css/new.css"  
import "./assets/dist/css/owl.carousel.css"  

import Multiselect from 'vue-multiselect'
library.add(fas)
const app = createApp(App);
app
.use(router)
.use(VueAxios, axios)
.use(VueReCaptcha, { siteKey: '6LcAhuYoAAAAAHNcjeoLHaEyxuJ-We390YG_4wZl' })
.use(store) 
.provide('$axios', axios)
.component('font-awesome-icon', FontAwesomeIcon) 
.component('Multiselect', Multiselect)
.component('VueDatePicker', VueDatePicker)
.mount('#app')

app.config.globalProperties.$axios = axios;
axios.defaults.withCredentials = true
axios.defaults.baseURL = '/web' 
app.config.globalProperties.$InitPage = function(){
    $(function () { 
    $('.navbar-collapse.offcanvas-nav.open').removeClass('open')
    // 完成後再引入這些項目
    if($('.JS_plugins').length==0){
        let script = document.createElement('script');
        script.className = "JS_plugins"
        script.type = 'text/javascript';
        script.src = './plugins.js';
        document.body.appendChild(script); 
    } 
        let script3 = document.createElement('script');
        script3.className = "JS_carousel"
        script3.type = 'text/javascript';
        script3.src = './owl.carousel.js';
        document.body.appendChild(script3); 
    
        $(".toTop").on("click", function (a) {
          return a.preventDefault(),
            $("html, body").animate({
              scrollTop: 0
            }, 0),
            !1
        })  
        $(".home-slider-s-scroll ").on("click", function (a) {
          return a.preventDefault(),
            $("html, body").animate({
              scrollTop:  window.innerWidth*0.5
            }, 300),
            !1
        })  
  });
}
app.config.globalProperties.$GetTimeStr = function(time){
  var monthStr = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December'
  }; 
  var dt = new Date( time);
  return  monthStr[ dt.getMonth()+1] +' '+ dt.getDate() +', '+ dt.getFullYear()  ; 
}
app.config.globalProperties.$GetQueryParams = function(url) {
  const queryParams = {};
  const queryString = url.split('?')[1]; 

  if (queryString) {
    const paramPairs = queryString.split('&'); 
    for (const pair of paramPairs) {
      const [key, value] = pair.split('=');
      queryParams[key] = value;
    }
  } 
  return queryParams;
}