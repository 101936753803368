import { createRouter, createWebHashHistory } from 'vue-router'
// import Login from '../views/baisc/Login.vue'
import Layout from '../../src/layouts/Layout.vue' 
import Article from '../views/main/Article.vue'
import ArticleDetail from '../views/main/ArticleDetail.vue'
import Contact from '../views/main/Contact.vue'
import Knowledge from '../views/main/Knowledge.vue' 
import KnowledgeDetail from '../views/main/KnowledgeDetail.vue' 
import About from '../views/main/About.vue'  
import Home from '../views/main/Home.vue'
import Service from  '../views/main/Service.vue'
import ServiceDetail from  '../views/main/ServiceDetail.vue'
import store from '../store'
import axios from 'axios';
import $ from 'jquery';
const routes = [
	{
		path: '', // 如果沒有填入路徑，home會是一級路由的預設
		redirect: '/home' 
	},
	{
		path: "/",
		name: "layout",
		component: Layout,
		meta:{
			keepAlive:false, 
		},
		children: [
			{
				path: "/home",
				name: "Home", 
				component: Home,  
				meta: { requiresAuth: true } 
			}, 
			{
				path: "/article",
				name: "Article", 
				component: Article,
				linkActiveClass: "active", 
				meta: { requiresAuth: true } 
			}, 
			{
				path: "/article-detail",
				name: "ArticleDetail", 
				component: ArticleDetail,
				linkActiveClass: "active", 
				meta: { requiresAuth: true } 
			}, 
			{
				path: "/knowledge",
				name: "Knowledge", 
				component: Knowledge,
				linkActiveClass: "active", 
				meta: { requiresAuth: true } 
			}, 
			{
				path: "/knowledge-detail",
				name: "KnowledgeDetail", 
				component: KnowledgeDetail,
				linkActiveClass: "active", 
				meta: { requiresAuth: true } 
			}, 
			{
				path: "/contact",
				name: "Contact",
				component: Contact,  
				linkActiveClass: "active",
				meta: { requiresAuth: true } 
			}, 
			{
				path: "/about",
				name: "About",
				component: About, 
				linkActiveClass: "active", 
				meta: { requiresAuth: true } 
			}, 
			{
				path: "/service",
				name: "Service",
				component: Service, 
				linkActiveClass: "active", 
				meta: { requiresAuth: true } 
			},  
			{
				path: "/service-detail",
				name: "ServiceDetail",
				component: ServiceDetail, 
				linkActiveClass: "active", 
				meta: { requiresAuth: true } 
			},  
			// 	path: "/url/04",
			// 	name: "page04", 
			// 	linkActiveClass: "active",
			// 	meta: { requiresAuth: true } // 需要驗證登入
			// }
		]
	}
];

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

// 假設有一個方法用於驗證TOKEN的有效性，可以根據您的需求進行修改
function isTokenValid() { 
	// 在此處添加您的驗證TOKEN的邏輯，例如檢查TOKEN是否有效
	// 返回 true 或 false，表示TOKEN是否有效
	var userInfo = JSON.parse(localStorage.getItem('userInfo'));
	if (!userInfo) return false;

	store.state.userInfo = userInfo; 
	var token = userInfo.token || store.state.token;  
	if (token){
		store.state.token=userInfo.token;
		return true;
	} 
	else
		return false;
}
async function WebsiteSetting() {  
	try { 
		let result = await axios.post('/website-setting/list', {
			"page": 1,
			"count": 10,
			"sort_field": "id",
			"sort_order": "desc",
			"is_output": 0,
			"search": {
				"id": [1],
			}
		},
			{
			headers: 
			{
			Authorization: `Bearer ${store.state.token}`  
			}
		}) 
		if (result.data.header.message == "Success") {  
			store.state.websiteSetting.search = result.data.data.list[0]; 
			return true
		}else return false
	}
	catch (err) {
		console.error(err);
		return false
	}  
	
}
async function loginAndGetToken() { 
	var payload ={
	"email": 'frontend@gmail.com',
	"g-recaptcha-response": "03AL8dmw8UPIxvXeVaO1Gk1mvwAROgQf3uG8gNmxMXYGvqesoVib0ZF6IIP-5gTkihO6J0MohkA5sQRIEiYGdQUzTt_TdrESK0yx6NBOMzMmVeNzNbz7TpGFRHH7VH9pt9DmMSu204ZgiMnLsmEVEPJiWXEvEd-nNApuTZSGRNmZrXCxTwaGZFVkOppscg33JwdIZiDJf8KvWIC_PTbKvbDUQV0XYGt5pshEBw1pUlaL3DWshgMpldBCPaX4nilzCVkiWdL_mjlolFP1M1MYqR5lnMqZhZZ9KF9Qh2xYXdoKldU2vLxAw0H4wmgKTxmYQeRXPbtfy8ML2ErO1H3bZw3C9m4q0aL-dCCvUVpfgQ3f5c7DtH294GCzChtKv8ex43-gujL5YnAefrqZhp8LU8VfetX5w4b3S-7Vuq1KcIQ3nnyBmjIjKK8x1WQPj_01DKHYpoZ8AXkIXoIUqpE9MTplNyk9LfWbQ0OiNZtUOnWQb1mSrytDOX7ErBEzqEMrnrmFLfFbXE7L_qrr2_xcIWTPzWlI6qC0JpYp9nufCnXOuLyEKhNTmiewtDU614HZgZkxbiRUOJFCFfOOia_v7d8xY178Eu82KipmsF2gDVFLcRYJAy_f58kDMRQlBorGVZynICFIVB6WcnYT1IgsGByleGB-VBkBSj2DDvYI3PruYFcHC7D019wRZdjtkAzEqykIIK2mY5p82jUDYJI-Sk3PMOU3xlJeynabPVSwZ5feqXfEJMPFql7tKpgXWaduGVa5BpLfDk3_wgkob1PU2xl51-wfzko7glh7jsPP8o_TdMu6GQCJWAoeewMOy4JlOzJT5Pxo4S2zMmDkj9g1qOLdWEeaABzVh_DSfevXs59DHt1G1vOqzjtwMqtyNygE69mz_oWs-v1IYbBFCT4LBiU0Rk2VNBM8ho-OCltgxwqhygCevALxAsPBxnuJdP5FjLDVbNWzMTl25x6-Bk5UU0HcXCWA8wEWmAtjiPF18I6BQ8qu28e6Yr8VnwHiVh-jBJKWNvohUhTR8he5cUnZpsPSoltgExgvuS7ovAZq8po_8LQzTMtoGZEjsFou0rf3VKJz2u3K1OaiP4cIQFEm_Rhex-WS8BkxmM1HmcbbyYv1lyQKmYUkVM9HcbM9SQ_kokZKh9On1LhltOJ31sagPG1kReF3MM19Zj66OfbRNEZ6dtAGgWe5qZ6MEjcfv1OP6meVlTF18vDfWYy3-GuY-YKJ0wsi2qNDXSFJKQdf_kNptpb8HkH_9dhaFB3nVlyTQGRDDQTdl_6o1liuDG5I4rkGeOcm-qxdOY7Wg6zxBFq2mraWXaNYkPa_ZLltpolbvfwCz9p5QhxvBrK74d5Ck0P7AIQhhndYywViOmzeE8fFaHuf5GNbIg-jB89o3WC_sLvFLpUKZy5y979xyVp6f28IYohQTd0u3hzlOkBwCYCaezvZP0X_ybHF-4O_-Yh3-fo_wRf2sQCAZGz7z5Vb1FgntHD2bK2fLgqg",
	"password": 'm%WkrZ?y$H$Wk6!d', 
	"saveToken": true
	}; 

	try {
		let result = await axios.post('/login',
			{
				"email": payload.email,
				"g-recaptcha-response": payload['g-recaptcha-response'],
				"password": payload.password
			}
		)
		if(result.status == 200){  
			if (result.data.header.message == "Success") {
						store.state.userInfo.password=payload.saveToken? payload.password:"";
						store.state.userInfo.token=result.data.data.access_tokens;
						store.state.userInfo.name=result.data.data.name;
						store.state.userInfo.email=payload.email;     
						//save userInfo in localStorage 
						store.state.token=result.data.data.access_tokens;
						localStorage.setItem("userInfo", JSON.stringify(store.state.userInfo));  
					} 
		}     
		return true
	}
	catch (err) {
		console.error(err);
	}  
}
router.beforeEach((to, from, next) => {  
	window.scroll(0, 0);
    $(function () { 
		$('.navbar-collapse.offcanvas-nav.open').removeClass('open')
	})
	if (to.matched.some(route => route.meta.requiresAuth)) { 
		// 需要驗證TOKEN
		if (isTokenValid()) { 
			WebsiteSetting().then(function (result) { 
				if(result)next();
				else {
					loginAndGetToken().then(function (result) {  
						if(result) WebsiteSetting().then(function (result) { 
							if(result)next(); 
						}); 
					});  
				}
		}); 
		} else {  
			// 無TOKEN  
			loginAndGetToken().then(function (result) { 
				if(result) WebsiteSetting().then(function (result) { 
					if(result)next(); 
			}); 
			});  
			// next();  
		}
	} else {
		// 不需要驗證TOKEN，允許訪問該頁面
		next();
	}
})
export default router
