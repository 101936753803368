<template>
  <div>
    <section class="page-title">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <ul class="bread">
              <li class="first">
                <router-link v-bind:to="'/home'"> 首頁
                </router-link>
                /&nbsp;
              </li>
              <li>
                <router-link v-bind:to="'/article'"> 成功案例
                </router-link> /&nbsp;
              </li>
              <li>
                <router-link v-if="type_id == 1" v-bind:to="'/article?type_id=1&keyword='">刑事案例
                </router-link>
                <router-link v-if="type_id == 2" v-bind:to="'/article?type_id=2&keyword='">民事案例
                </router-link>
                <router-link v-if="type_id == 3" v-bind:to="'/article?type_id=3&keyword='">家事案例
                </router-link>/&nbsp;
              </li>
              <li v-if="article && article[0]">
                <h1>
                  <router-link v-bind:to="'/article-detail?id=' + article[0].id"> {{ article[0].title
                  }}
                  </router-link>
                </h1>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class=" py-5" v-if="article">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-12  text-center" style="
    gap: 10px;
    display: flex;
    flex-direction: column;margin-bottom: 100px;">
            <div class="  text-center input-search mb-3">
              <input type="text" v-model="keyword" placeholder="案例搜尋" style="width: -webkit-fill-available;">
              <router-link v-bind:to="'/article?type_id=' + '&keyword=' + keyword">
                <button>
                  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
                    <g id="SVGRepo_bgCarrier" stroke-whiteidth="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M17.0392 15.6244C18.2714 14.084 19.0082 12.1301 19.0082 10.0041C19.0082 5.03127 14.9769 1 10.0041 1C5.03127 1 1 5.03127 1 10.0041C1 14.9769 5.03127 19.0082 10.0041 19.0082C12.1301 19.0082 14.084 18.2714 15.6244 17.0392L21.2921 22.707C21.6828 23.0977 22.3163 23.0977 22.707 22.707C23.0977 22.3163 23.0977 21.6828 22.707 21.2921L17.0392 15.6244ZM10.0041 17.0173C6.1308 17.0173 2.99087 13.8774 2.99087 10.0041C2.99087 6.1308 6.1308 2.99087 10.0041 2.99087C13.8774 2.99087 17.0173 6.1308 17.0173 10.0041C17.0173 13.8774 13.8774 17.0173 10.0041 17.0173Z"
                        fill="white"></path>
                    </g>
                  </svg>
                </button>
              </router-link>
            </div>
            <router-link v-bind:to="'/article?type_id=1&keyword='">
              <button class="btn-outline" v-bind:class="{ 'active': type_id == 1 }"
                style="font-size: 20px;padding: 3px 25px;width: 100%;">刑事案例</button>
            </router-link>
            <router-link v-bind:to="'/article?type_id=2&keyword='">
              <button class="btn-outline" v-bind:class="{ 'active': type_id == 2 }"
                style="font-size: 20px;padding: 3px 25px;width: 100%;">民事案例</button>
            </router-link>
            <router-link v-bind:to="'/article?type_id=3&keyword='">
              <button class="btn-outline" v-bind:class="{ 'active': type_id == 3 }"
                style="font-size: 20px;padding: 3px 25px;width: 100%;">家事案例</button>
            </router-link>
          </div>
          <div class="col-lg-9 col-12 mb-4 " v-for="(item, index) in article" :key="index">
            <div class="success-post">
              <h3>{{ item.title }}</h3>
              <hr>
              <time>{{ $GetTimeStr(item.created_at) }}</time>
              <div class="html-content" v-html="item.content">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>

import { mapGetters } from 'vuex';
import axios from 'axios';
export default {
  name: 'ServiceDtail',
  components: {
  },
  computed: {
    ...mapGetters(['InitPage'])
  }
  ,
  data() {
    return {
      type_id: null,
      article: [],
      article_type_id: null,
      keyword: '',
    }
  },
  created() {
    var self = this;
    var _s = this.$GetQueryParams(window.location.href);
    this.id = parseInt(_s.id);

    var payload = {};
    if (_s.id) payload.id = [parseInt(_s.id)];


    this.getArticle(
      payload
    ).then(function () {
      self.$InitPage();
    });
  },
  activated() {
  },
  methods: {
    getArticle: async function (_s) {
      // var self = this;
      try {
        let result = await axios.post('/article/list', {
          "page": 1,
          "count": 1,
          "sort_field": "order",
          "sort_order": "asc",
          "is_output": 0,
          "search": _s
        },
          {
            headers:
            {
              Authorization: `Bearer ${this.$store.state.token}`
            }
          })
        if (result.data.header.message == "Success") {
          this.article = result.data.data.list;
          this.type_id = result.data.data.list[0].article_type_id
          return true
        }
      }
      catch (err) {
        console.error(err);
      }
    },
    ChangePage: function (num) {
      this.$router.push({ path: '/article', hash: '?keyword=' + this.keyword + '&type_id=' + this.type_id + '&page=' + num })
    }
  },
}
</script>
 