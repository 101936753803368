<template>
  <div>
    <section class="page-title">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <ul class="bread">
              <li class="first">
                <router-link v-bind:to="'/home'"> 首頁
                </router-link>
                /&nbsp;
              </li>
              <li>
                <router-link v-bind:to="'/service'"> 服務項目
                </router-link> /&nbsp;
              </li>
              <li v-if="service && service[0]">
                <h1>
                  <router-link v-bind:to="'/service-detail?id=' + service[0].id">
                    {{ service[0].title }}
                  </router-link>
                </h1>
              </li>
            </ul>
          </div>
          <!-- <div class="col-md-12">
            <div class="page-title-text">
              <hr />
              <h2>服務項目</h2>
              <hr />
            </div>
          </div> -->
        </div>
      </div>
    </section>
    <section class="home-service py-13">
      <div class="container" v-if="service && service[0]">
        <div class="row">
          <div class="col-12 service-info-top">
            <div class=" service-info-img">
              <img v-bind:src="'data:image/jpeg;base64, ' + service[0].image">
            </div>
            <div class="service-info-text">
              <div class="service-info-text-title">
                <h3>{{ service[0].title }}</h3><span></span>
              </div>
              <p>
                {{ service[0].desc }}
              </p>
              <div class="service-info-ul md-hide">
                <ul>
                  <template v-for="(item, index) in service[0].items" :key="index">
                    <li v-if="index % 3 == 0">{{ item.name }}</li>
                  </template>
                </ul>
                <ul>
                  <template v-for="(item, index) in service[0].items" :key="index">
                    <li v-if="index % 3 == 1">{{ item.name }}</li>
                  </template>
                </ul>
                <ul>
                  <template v-for="(item, index) in service[0].items" :key="index">
                    <li v-if="index % 3 == 2">{{ item.name }}</li>
                  </template>
                </ul>
              </div>
              <div class="service-info-ul md-show">
                <ul>
                  <template v-for="(item, index) in service[0].items" :key="index">
                    <li v-if="index % 2 == 0">{{ item.name }}</li>
                  </template>
                </ul>
                <ul>
                  <template v-for="(item, index) in service[0].items" :key="index">
                    <li v-if="index % 2 == 1">{{ item.name }}</li>
                  </template>
                </ul>
              </div>
            </div>
          </div>
          <div class="row" v-if="service && service[0]">
            <div class="col-12 service-info-qa">
              <h4>{{ service[0].title }}常見問題</h4>
              <div class="dropDiv" v-for="(item, index) in service[0].questions" :key="index">
                <div class="dropTitle txt-Serif ">
                  <span class="txt-Libre ">Q:</span>{{ item.content }}
                </div>
                <div class="dropInner txt-Serif " style="display: none;">
                  <span class="txt-Libre ">A:</span> {{ item.answer }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import axios from 'axios';
import $ from 'jquery';
export default {
  name: 'ServiceDtail',
  components: {
  },
  computed: {
    ...mapGetters(['InitPage'])
  }
  ,
  data() {
    return {
      service: [],
    }
  },
  created() {
    var self = this;
    var _s = this.getQueryParams(window.location.href);
    this.getService(
      {
        id: [parseInt(_s.id)]
      }
    ).then(function () {
      self.$InitPage();
    });
  },
  activated() {
  },
  methods: {
    getService: async function (_s) {
      try {
        let result = await axios.post('/offering/list', {
          "page": 1,
          "count": 100,
          "sort_field": "order",
          "sort_order": "asc",
          "is_output": 0,
          "search": _s
        },
          {
            headers:
            {
              Authorization: `Bearer ${this.$store.state.token}`
            }
          })
        if (result.data.header.message == "Success") {
          this.service = result.data.data.list;

          $(function () {
            $('.dropDiv .dropTitle').click(function () {
              if ($(this).parent().hasClass('dropDivActive')) {
                $(this).siblings(".dropInner").slideUp(500).parent().removeClass('dropDivActive');
              } else {
                $('.dropDiv .dropTitle').not($(this)).siblings(".dropInner").slideUp().parent().removeClass('dropDivActive');
                $(this).siblings(".dropInner").slideDown(500).parent().addClass('dropDivActive');
              }
            })
          })
          return true
        }
      }
      catch (err) {
        console.error(err);
      }

    },
    getQueryParams(url) {
      const queryParams = {};
      const queryString = url.split('?')[1]; // 获取 "?" 后面的部分

      if (queryString) {
        const paramPairs = queryString.split('&');

        for (const pair of paramPairs) {
          const [key, value] = pair.split('=');
          queryParams[key] = value;
        }
      }

      return queryParams;
    }

  },
}
</script>
 