import { createStore } from 'vuex'
import axios from 'axios';
import router from '../router' 
var API_URL = {
  login:"/login",
  logout:"/logout",
  resetPassword:"/reset-password",
  generatePassword:'/generate-password',
  userAllPermissions:"/user/get-all-permissions", 
  navigationItem:"/navigation-item/list", 

  //content
  contentList: "/content/list", 
  contentUpdate: "/content/update", 
  contentCreate: "/content/create", 
  contentDelete: "/content/delete",
  //article
  articleList: "/article/list", 
  articleUpdate: "/article/update", 
  articleCreate: "/article/create", 
  articleDelete: "/article/delete",
 ///article-type/list
  articleTypeList: "/article-type/list", 
  //knowledge
  knowledgeList: "/knowledge/list", 
  knowledgeUpdate: "/knowledge/update", 
  knowledgeCreate: "/knowledge/create", 
  knowledgeDelete: "/knowledge/delete",
  //WebsiteSetting
  websiteSettingList: "/website-setting/list", 
  websiteSettingUpdate: "/website-setting/update", 
  //carouselList
  carouselList:"carousel/list",
  carouselUpdate:"carousel/update",
  carouselCreate:"carousel/create",
  carouselDelete:"carousel/delete",
}
export default createStore({
  state: {
    DOMAIN_URL :process.env.VUE_APP_BACKEND_URL,   
    userInfo:{
      token:"",
      email:"",
      password:"",
      name:"", 
    },
    generatePassword:{},
    loginInfo:{},
    token: '', 
    sideBar: [], 
    ChangePswInfo:{},
    allPermissions:{},
    loading:false,

    //--api--
    articleType:{
      dropDown:[],  
    },
    article:{
      dropDown:[],  
      search:[],  
      update:[],  
      delete:[],  
      create:[],    
    },
    knowledge:{
      dropDown:[],  
      search:[],  
      update:[],  
      delete:[],  
      create:[],    
    },
    content:{
      dropDown:[],  
      search:[],  
      update:[],  
      delete:[],  
      create:[],    
    },
    websiteSetting:{ 
      search:[],  
      update:[],     
    },
    carousel:{
      dropDown:[],  
      search:[],  
      update:[],  
      delete:[],  
      create:[],    
    },
 
  },
  mutations: {
    SetLoginInfo(state,payload){
      state.loginInfo = payload;  
    },
    SetLoginToken(state,payload) {
      state.token = payload;  
      localStorage.setItem("userInfo", JSON.stringify(state.userInfo));  
      this.dispatch('GetSideBar');
    },
    DeleteLoginToken(state) {
      state.userInfo.token="";
      state.userInfo.password="";
      state.token = "";
      localStorage.setItem("userInfo", JSON.stringify(state.userInfo)); 
      router.push({ name: 'login' })
    },
    SetSideBar(state, payload) {  
      state.sideBar = payload;
      this.dispatch('GetAllPermissions');
    },
    SetChangePsw(state, payload) { 
      state.ChangePswInfo = payload;//監聽並關閉視窗
    },
    SetGeneratePassword(state, payload) { 
      state.generatePassword = payload;
    },
    SetAllPermissions(state, payload) {  
      state.allPermissions = payload;
    },
    
    /*--content--*/ 
    SetSearchContent(state, payload) { 
      state.content.search = payload;  
    }, 
    SetDropDownContent(state, payload) {
      state.content.dropDown = payload; 
    }, 
    SetUpdateContent(state, payload) {
      state.content.update = payload; 
    }, 
    SetDeleteContent(state, payload) {
      state.content.delete = payload; 
    }, 
    SetCreateContent(state, payload) {
      state.content.create = payload; 
    },
    /*--Articl--*/ 
    SetSearchArticle(state, payload) { 
      state.article.search = payload;  
    }, 
    SetDropDownArticle(state, payload) {
      state.article.dropDown = payload; 
    }, 
    SetUpdateArticle(state, payload) {
      state.article.update = payload; 
    }, 
    SetDeleteArticle(state, payload) {
      state.article.delete = payload; 
    }, 
    SetCreateArticle(state, payload) {
      state.article.create = payload; 
    },  
    /*--Knowledge--*/ 
    SetSearchKnowledge(state, payload) { 
      state.knowledge.search = payload;  
    }, 
    SetDropDownKnowledge(state, payload) {
      state.knowledge.dropDown = payload; 
    }, 
    SetUpdateKnowledge(state, payload) {
      state.knowledge.update = payload; 
    }, 
    SetDeleteKnowledge(state, payload) {
      state.knowledge.delete = payload; 
    }, 
    SetCreateKnowledge(state, payload) {
      state.knowledge.create = payload; 
    },  
    /*--ArticlType--*/  
    SetDropDownArticleType(state, payload) {
      state.articleType.dropDown = payload; 
    },   
    ShowLoaderPanel(state, payload) {
      state.loading= payload; 
    }, 
    /*--WebsiteSetting--*/ 
    SetSearchWebsiteSetting(state, payload) { 
      state.websiteSetting.search = payload;  
    },  
    SetUpdateWebsiteSetting(state, payload) {
      state.websiteSetting.update = payload; 
    }, 
    /*--carousel--*/ 
    SetSearchCarousel(state, payload) { 
      state.carousel.search = payload;  
    }, 
    SetDropDownCarousel(state, payload) {
      state.carousel.dropDown = payload; 
    }, 
    SetUpdateCarousel(state, payload) {
      state.carousel.update = payload; 
    }, 
    SetDeleteCarousel(state, payload) {
      state.carousel.delete = payload; 
    }, 
    SetCreateCarousel(state, payload) {
      state.carousel.create = payload; 
    },  
  },
  getters: {
    CheckPermissions: (state) => (key) => {  
      for(var i in state.allPermissions){
        if(state.allPermissions[i].name==key)return true;
      }
      return true;
      // return false; 
    },  
  },
  actions: { 
    GetGeneratePassword({ commit }, payload) { 
      axios.post(API_URL.generatePassword,
        {
          "number": payload, 
        },
        {
          headers: {
            Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
          }
        }
      ).then(result => { 
        commit('SetGeneratePassword',result);   
      }).catch(error => {
        throw new Error(`API ${error}`);
      });
    },
    GetLoginToken({ commit }, payload) { 
    // console.log(process.env.VUE_APP_BACKEND_URL) 
      axios.post(API_URL.login,
        {
          "email": payload.email,
          "g-recaptcha-response": payload['g-recaptcha-response'],
          "password": payload.password
        }
      ).then(result => { 
        commit('SetLoginInfo',result.data); 
        if (result.data.header.message == "Success") {
          this.state.userInfo.password=payload.saveToken? payload.password:"";
          this.state.userInfo.token=result.data.data.access_tokens;
          this.state.userInfo.name=result.data.data.name;
          this.state.userInfo.email=payload.email;  

          commit('SetLoginToken',result.data.data.access_tokens); 
          router.push({ name: 'home' })
        }

      }).catch(error => {
        throw new Error(`API ${error}`);
      });
    },
    GetSideBar({ commit }) {
      axios.post(API_URL.navigationItem,
        {
          "page": 1,
          "count": 100,
          "sort_field": "id",
          "sort_order": "asc",
          "is_output": 0
        }, {
        headers: {
          Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
        }
      })
        .then(result => { 
          if (result.data.header.message == "Success") {
            commit('SetSideBar', result.data.data.list);
          }
        }).catch(error => {
          this.dispatch('Logout');
          throw new Error(`API ${error}`);
        });
    },
    Logout({ commit }) { 
      axios.post(API_URL.logout,
        {}, 
        {
        headers: {
          Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
        }
      })
        .then(result => {
          result;
        }).catch(error => {
          throw new Error(`API ${error}`);
        }); 
        commit('DeleteLoginToken');
    },
    ChangePsw({commit},payload){
      commit('SetChangePsw', '');
      axios.post(API_URL.resetPassword,
        payload, 
        {
        headers: {
          Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
        }
      }
      ).then(result => { 
        if (result.data.header.message == "Success") {   
          if(this.state.userInfo.password){
            this.state.userInfo.password = payload.password;
            localStorage.setItem("userInfo", JSON.stringify(this.state.userInfo)); 
          }  
        } 
        commit('SetChangePsw', result); 
      }).catch(error => {
        throw new Error(`API ${error}`);
      });
    },
    GetAllPermissions({commit}){
      axios.post(API_URL.userAllPermissions,{},
        {
        headers: {
          Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
        }
      }
      ).then(result => {   
        if (result.data.header.message == "Success") {    
          commit('SetAllPermissions', result.data.data.list);  
        } 
      }).catch(error => {
        throw new Error(`API ${error}`);
      });
    },  
    /*--Content--*/
    GetSearchContent({ commit },payload) {
      axios.post(API_URL.contentList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetSearchContent',result.data.data); 
          }
        }).catch(error => {
 
          throw new Error(`API ${error}`);
        });
    },
    GetDropDownContent({ commit },payload) {
      axios.post(API_URL.contentList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetDropDownContent',result.data.data.list); 
          }
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    GetCreateContent({ commit },payload) { 
      commit('ShowLoaderPanel',true);
      axios.post(API_URL.contentCreate, payload,
        {
          headers: 
          {
            'Content-Type':'multipart/form-data',
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetCreateContent',result);  
            commit('ShowLoaderPanel',false);
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },
    GetDeleteContent({ commit },payload) {
      axios.post(API_URL.contentDelete , payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetDeleteContent',result);  
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },
    GetUpdateContent({ commit },payload) {
      axios.post(API_URL.contentUpdate, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetUpdateContent',result);  
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    /*--Article--*/
    GetSearchArticle({ commit },payload) { 
      axios.post(API_URL.articleList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetSearchArticle',result.data.data); 
          }
        }).catch(error => {
 
          throw new Error(`API ${error}`);
        });
    },
    GetDropDownArticle({ commit },payload) {
      axios.post(API_URL.articleList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetDropDownArticle',result.data.data.list); 
          }
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    GetCreateArticle({ commit },payload) { 
      commit('ShowLoaderPanel',true);
      axios.post(API_URL.articleCreate, payload,
        {
          headers: 
          {
            'Content-Type':'multipart/form-data',
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetCreateArticle',result);  
            commit('ShowLoaderPanel',false);
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },
    GetDeleteArticle({ commit },payload) {
      axios.post(API_URL.articleDelete , payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetDeleteArticle',result);  
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },
    GetUpdateArticle({ commit },payload) {
      axios.post(API_URL.articleUpdate, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetUpdateArticle',result);  
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    /*--Knowledge--*/
    GetSearchKnowledge({ commit },payload) { 
      axios.post(API_URL.knowledgeList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetSearchKnowledge',result.data.data); 
          }
        }).catch(error => {
 
          throw new Error(`API ${error}`);
        });
    },
    GetDropDownKnowledge({ commit },payload) {
      axios.post(API_URL.knowledgeList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetDropDownKnowledge',result.data.data.list); 
          }
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    GetCreateKnowledge({ commit },payload) { 
      commit('ShowLoaderPanel',true);
      axios.post(API_URL.knowledgeCreate, payload,
        {
          headers: 
          {
            'Content-Type':'multipart/form-data',
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetCreateKnowledge',result);  
            commit('ShowLoaderPanel',false);
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },
    GetDeleteKnowledge({ commit },payload) {
      axios.post(API_URL.knowledgeDelete , payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetDeleteKnowledge',result);  
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },
    GetUpdateKnowledge({ commit },payload) {
      axios.post(API_URL.knowledgeUpdate, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetUpdateKnowledge',result);  
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    /*--articleType--*/ 
    GetDropDownArticleType({ commit },payload) {
      axios.post(API_URL.articleTypeList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetDropDownArticleType',result.data.data.list); 
          }
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    /*--WebsiteSetting--*/
    GetSearchWebsiteSetting({ commit },payload) { 
      axios.post(API_URL.websiteSettingList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetSearchWebsiteSetting',result.data.data); 
          }
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },  
    GetUpdateWebsiteSetting({ commit },payload) {
      axios.post(API_URL.websiteSettingUpdate, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetUpdateWebsiteSetting',result);  
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    /*--carousel--*/
    GetSearchCarousel({ commit },payload) { 
      axios.post(API_URL.carouselList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetSearchCarousel',result.data.data); 
          }
        }).catch(error => {
 
          throw new Error(`API ${error}`);
        });
    },
    GetDropDownCarousel({ commit },payload) {
      axios.post(API_URL.carouselList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetDropDownCarousel',result.data.data.list); 
          }
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    GetCreateCarousel({ commit },payload) { 
      commit('ShowLoaderPanel',true);
      axios.post(API_URL.carouselCreate, payload,
        {
          headers: 
          {
            'Content-Type':'multipart/form-data',
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetCreateKnowledge',result);  
            commit('ShowLoaderPanel',false);
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },
    GetDeleteCarousel({ commit },payload) {
      axios.post(API_URL.carouselDelete , payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetDeleteCarousel',result);  
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },
    GetUpdatCarousel({ commit },payload) {
      axios.post(API_URL.carouselUpdate, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetUpdateCarousel',result);  
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
  },
  modules: {
  }
})
 
 