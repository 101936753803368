<template>
  <div>
    <section class="home-slider-s">
      <div id="home-slider" class="owl-carousel owl-theme">
        <div v-for="(item, index) in carousel" :key="index" class="home-slider-img" v-bind:style="cssBgImg(item.image)">
        </div>
      </div>
      <div class="home-slider-s-scroll"></div>
    </section>
    <section class="home-about">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 bg-about-01">
            <h2>
              關於王邵威律師
            </h2>
            <p v-html="$store.state.websiteSetting.search.about.replaceAll('\n', '<br />')">
            </p>
            <router-link v-bind:to="'/about'">
              <button class="btn-outline">了解更多</button>
            </router-link>
          </div>
          <div class="col-lg-6 bg-about-02">
            <div class="about-outline-text">About</div>
            <img class="about-img-lawyer"
              v-bind:src="'data:image/jpeg;base64, ' + $store.state.websiteSetting.search.image">
          </div>
        </div>
      </div>
    </section>
    <section class="home-service py-13">
      <div class="service-outline-text">Services</div>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h2>
              服務項目
            </h2>
          </div>
          <div class="col-md-12" style="display: flex;flex-wrap: wrap;">
            <div class="home-service-itme" v-for="(item, index) in service" :key="index">
              <router-link v-bind:to="'/service-detail?id=' + item.id">
                <img v-bind:src="'data:image/jpeg;base64, ' + item.image">
                <h3>
                  {{ item.title }}
                </h3>
              </router-link>
            </div>
          </div>
          <div class="col-md-12 text-center">

            <router-link v-bind:to="'/service'">
              <button class="btn-outline mt-5">了解更多</button>
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <section class="home-success ">
      <div class="container py-13">
        <div class="success-outline-text">Case Studies</div>
        <div class="row">
          <div class="col-md-12">
            <hr class="mb-4">
            <div class="owl-top">
              <h2>
                成功案例
              </h2>
              <div class="owl-btn">
                <router-link v-bind:to="'/article?type_id=1&keyword='">
                  <button class="btn-fill mt-5"><span>更多成功案例</span><img src="../../assets/dist/img/arrow.png"></button>
                </router-link>
                <button class="btn-outline prev mt-5">
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em"
                    viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    <path
                      d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                  </svg>
                </button>
                <button class="btn-outline next mt-5">
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em"
                    viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    <path
                      d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-12 text-center p-0">
            <div id="owl-success" class="owl-carousel owl-theme">
              <div class="success-item" v-for="(item, index) in article" :key="index">
                <div class="success-item-type">
                  <span>{{ item.article_type.name }}</span>
                  <time>{{ $GetTimeStr(item.created_at) }}</time>
                </div>
                <h3>{{ item.title }}</h3>
                <p>{{ item.desc }}</p>
                <router-link v-bind:to="'/article-detail?id=' + item.id">
                  <button class="btn-outline">了解更多</button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="home-knowledge ">
      <div class="container py-13">
        <div class="knowledge-outline-text">knowledge</div>
        <div class="row">
          <div class="col-md-12">
            <hr class="hr-100 mb-4">
            <div class="owl-top">
              <h2>
                法律小知識
              </h2>
              <div class="owl-btn">
                <router-link v-bind:to="'/knowledge?keyword='">
                  <button class="btn-fill mt-5">更多法律知識<img src="../../assets/dist/img/arrow.png"></button>
                </router-link>
                <button class="btn-outline prev mt-5">
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em"
                    viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    <path
                      d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                  </svg>
                </button>
                <button class="btn-outline next mt-5"><svg xmlns="http://www.w3.org/2000/svg" height="1em"
                    viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    <path
                      d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-12 text-center p-0">
            <div id="owl-knowledge" class="owl-carousel owl-theme">
              <div class="knowledge-item" v-for="(item, index) in knowledge" :key="index">
                <div class="knowledge-item-img" v-bind:style="cssBgImg(item.image)">
                </div>
                <div class="knowledge-item-time">
                  <time>{{ $GetTimeStr(item.created_at) }}</time>
                  <hr>
                </div>
                <h3>{{ item.title }}</h3>
                <p>{{ item.desc }}</p>
                <router-link v-bind:to="'/knowledge-detail?id=' + item.id">
                  <button class="btn-outline">了解更多</button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="home-contact">
      <div class="container">
        <div class="contact-outline-text">Consultation</div>
        <form class="row row-mid">
          <div class="col-xl-5 bg-contact-01">
            <div class="contact-left">
              <h3>
                諮詢須知
              </h3>
              <p v-html="$store.state.websiteSetting.search.consultation_form_content.replaceAll('\n', '<br />')">
              </p>
            </div>
          </div>
          <div class="col-xl-7 bg-contact-02  ">
            <h2>
              預約諮詢
            </h2>
            <p v-html="$store.state.websiteSetting.search.consultation_form_info.replaceAll('\n', '<br />')">
            </p>
            <div class="contact-form">
              <label>姓名<span class="required-star">*</span></label>
              <div>
                <input v-model="form.name" type="text" class="form-control">
              </div>
              <label>稱謂<span class="required-star">*</span></label>
              <div style="display: flex;gap: 25px;">
                <div class="form-check mb-0">
                  <input v-model="form.gender" class="form-check-input" type="radio" name="gender" id="gender1"
                    value="Male" checked>
                  <label class="form-check-label" for="gender1">
                    先生
                  </label>
                </div>
                <div class="form-check  mb-0">
                  <input v-model="form.gender" class="form-check-input" type="radio" name="gender" id="gender2"
                    value="Female">
                  <label class="form-check-label" for="gender2">
                    小姐
                  </label>
                </div>
              </div>
              <label>電話<span class="required-star">*</span></label>
              <div>
                <input v-model="form.mobile" type="text" class="form-control">
              </div>
              <label>諮詢問題<span class="required-star">*</span></label>
              <div>
                <textarea v-model="form.question" class="form-control" rows="6"></textarea>
              </div>
              <!-- <label>驗證碼<span class="required-star">*</span></label>
              <div>
                <input type="text" class="form-control">
              </div> -->
            </div>
            <p class="errMsg">{{ msg }}</p>
            <div style="text-align: center;margin: 1em 0  ">
              <button class="btn-fill" @click="sendForm($event) && recaptcha(form)">送出</button>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { useReCaptcha } from 'vue-recaptcha-v3'
import axios from 'axios';
import store from '../../store'
export default {
  setup() {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

    async function createConsultation(form) {
      try {
        let result = await axios.post('/consultation-form/create',
          form
          ,
          {
            headers:
            {
              Authorization: `Bearer ${store.state.token}`
            }
          })

        store.commit('SetCreateContent', result)
        if (result.data.header.message == "Success") {
          alert('諮詢表單發送成功！');
          window.location.reload();
          return true
        } else {
          alert('送出失敗')
        }
      }
      catch (err) {
        console.error(err);
      }
    }

    // submit 回傳一組 token，並把 token 傳給後端驗證 
    const recaptcha = async (form) => {
      await recaptchaLoaded();
      const token = await executeRecaptcha('login');
      form["g-recaptcha-response"] = token;
      createConsultation(form);
    }
    return {
      recaptcha,
    }
  },
  name: 'Home',
  components: {
  },
  computed: {
    ...mapGetters(['InitPage'])
  }
  ,
  data() {
    return {
      carousel: [],
      service: [],
      knowledge: [],
      article: [],
      form: {
        name: null,
        gender: 'Male',
        mobile: null,
        question: null,
        is_processed: 0
      },
      msg: ""
    }
  },
  created() {
    var self = this;
    self.getService();
    self.getCarousel().then(function () {
      self.getArticle().then(function () {
        self.getKnowledge().then(function () {
          self.$InitPage();
        });
      });
    });
  },
  methods: {
    getCarousel: async function () {
      try {
        let result = await axios.post('/carousel/list', {
          "page": 1,
          "count": 5,
          "sort_field": "order",
          "sort_order": "asc",
          "is_output": 0,
          "search": {
            "is_show": [1]
          },
        },
          {
            headers:
            {
              Authorization: `Bearer ${this.$store.state.token}`
            }
          })
        if (result.data.header.message == "Success") {
          this.carousel = result.data.data.list;
          return true
        }
      }
      catch (err) {
        console.error(err);
      }
    },
    getService: async function () {
      try {
        let result = await axios.post('/offering/list', {
          "page": 1,
          "count": 100,
          "sort_field": "order",
          "sort_order": "asc",
          "is_output": 0,
        },
          {
            headers:
            {
              Authorization: `Bearer ${this.$store.state.token}`
            }
          })
        if (result.data.header.message == "Success") {
          this.service = result.data.data.list;
          return true
        }
      }
      catch (err) {
        console.error(err);
      }

    },
    getKnowledge: async function () {
      try {
        let result = await axios.post('/knowledge/list', {
          "page": 1,
          "count": 10,
          "sort_field": "order",
          "sort_order": "asc",
          "is_output": 0,
        },
          {
            headers:
            {
              Authorization: `Bearer ${this.$store.state.token}`
            }
          })
        if (result.data.header.message == "Success") {
          this.knowledge = result.data.data.list;
          return true
        }
      }
      catch (err) {
        console.error(err);
      }

    },
    getArticle: async function () {
      try {
        let result = await axios.post('/article/list', {
          "page": 1,
          "count": 5,
          "sort_field": "order",
          "sort_order": "asc",
          "is_output": 0,
        },
          {
            headers:
            {
              Authorization: `Bearer ${this.$store.state.token}`
            }
          })
        if (result.data.header.message == "Success") {
          this.article = result.data.data.list;
          return true
        }
      }
      catch (err) {
        console.error(err);
      }

    },
    cssBgImg: function (url) {
      return {
        color: 'red',
        fontSize: '13px',
        'background-image': 'url("data:image/jpeg;base64, ' + url + '")'
      }
    },
    sendForm: function (e) {
      e.preventDefault();
      this.msg = '';
      if (!this.form.name) {
        this.msg = '姓名 為必填'
        return
      }
      if (!this.form.gender) {
        this.msg = '稱謂 為必填'
        return
      }
      if (!this.form.mobile) {
        this.msg = '電話 為必填'
        return
      }
      if (!this.form.question) {
        this.msg = '諮詢問題 為必填'
        return
      }
      return true;
      // this.createConsultation();
    }
  },
}
</script>
 