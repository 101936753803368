<template>
  <div>
    <section class="page-title">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <ul class="bread">
              <li class="first">
                <router-link v-bind:to="'/home'"> 首頁
                </router-link>
                /&nbsp;
              </li>
              <li>
                <h1>
                  <router-link v-bind:to="'/about'">
                    關於王邵威律師
                  </router-link>
                </h1>
              </li>
            </ul>
          </div>
          <div class="col-md-12">
            <div class="page-title-text">
              <hr />
              <h2>關於王邵威律師</h2>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="about-top" v-if="about && about[0]">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 ">
            <h3>專長<span></span></h3>
            <div class="about-top-p" v-html="about[0].lawyer_skill[0].name.replaceAll('\n', '<br />')">
            </div>
            <h3>經歷<span></span></h3>
            <div class="about-top-p unPhone">
              <ul>
                <template v-for="(item, index) in about[0].lawyer_experience" :key="index">
                  <li v-if="index % 2 == 0">{{ item.name }}</li>
                </template>
              </ul>
              <ul>
                <template v-for="(item, index) in about[0].lawyer_experience" :key="index">
                  <li v-if="index % 2 == 1">{{ item.name }}</li>
                </template>
              </ul>
            </div>
            <div class="about-top-p isPhone">
              <ul>
                <template v-for="(item, index) in about[0].lawyer_experience" :key="index">
                  <li>{{ item.name }}</li>
                </template>
              </ul>
            </div>
            <h3>專業證書<span></span></h3>
            <div class="about-top-p">
              <ul>
                <template v-for="(item, index) in about[0].lawyer_credential" :key="index">
                  <li>{{ item.name }}</li>
                </template>
              </ul>
            </div>
          </div>
          <div class="col-lg-5 about-top-img">
            <img v-bind:src="'data:image/jpeg;base64, ' + about[0].lawyer_photo[0].image" />
            <img v-bind:src="'data:image/jpeg;base64, ' + about[0].lawyer_photo[1].image" />
          </div>
        </div>
      </div>
    </section>
    <section class="about-bottom" v-if="about && about[0]">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 ">
            <img v-bind:src="'data:image/jpeg;base64, ' + about[0].lawyer_photo[2].image" />
          </div>
          <div class="col-lg-7 " style="position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;">
            <h3>
              <div>{{ about[0].title }}</div><span></span>
            </h3>
            <p v-html="about[0].content.replaceAll('\n', '<br />')"> </p>
            <div class="about-bottom-big">
              LAWYER WANG
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>

import { mapGetters } from 'vuex';
import axios from 'axios';
export default {
  name: 'About',
  components: {
  },
  computed: {
    ...mapGetters(['InitPage'])
  }
  ,
  data() {
    return {
      about: [],
    }
  },
  created() {
    var self = this;
    this.getAbout().then(function () {
      self.$InitPage();
    });
  },
  activated() {
  },
  methods: {
    getAbout: async function () {
      try {
        let result = await axios.post('/lawyer/list', {
          "page": 1,
          "count": 1,
          "sort_field": "id",
          "sort_order": "asc",
          "is_output": 0,
        },
          {
            headers:
            {
              Authorization: `Bearer ${this.$store.state.token}`
            }
          })
        if (result.data.header.message == "Success") {
          this.about = result.data.data.list;
          return true
        }
      }
      catch (err) {
        console.error(err);
      }

    },
    cssBgImg: function (url) {
      return {
        color: 'red',
        fontSize: '13px',
        'background-image': 'url("data:image/jpeg;base64, ' + url + '")'
      }
    }
  },
}
</script>
 