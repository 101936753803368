<template>
  <div>
    <section class="page-title">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <ul class="bread">
              <li class="first">
                <router-link v-bind:to="'/home'"> 首頁
                </router-link>
                /&nbsp;
              </li>
              <li>
                <h1>
                  <router-link v-bind:to="'/service'"> 服務項目
                  </router-link>
                </h1>
              </li>
            </ul>
          </div>
          <div class="col-md-12">
            <div class="page-title-text">
              <hr />
              <h2>服務項目</h2>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="home-service py-13">
      <!-- <div class="service-outline-text">Services</div> -->
      <div class="container">
        <div class="row">
          <div class="col-md-12" style="display: flex;flex-wrap: wrap;">
            <div class="home-service-itme" v-for="(item, index) in service" :key="index">
              <router-link v-bind:to="'/service-detail?id=' + item.id">
                <img v-bind:src="'data:image/jpeg;base64, ' + item.image">
                <h3>
                  {{ item.title }}
                </h3>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>

import { mapGetters } from 'vuex';
import axios from 'axios';
export default {
  name: 'Service',
  components: {
  },
  computed: {
    ...mapGetters(['InitPage'])
  }
  ,
  data() {
    return {
      service: [],
    }
  },
  created() {
    var self = this;
    this.getService().then(function () {
      self.$InitPage();
    });
  },
  activated() {
  },
  methods: {
    getService: async function () {
      try {
        let result = await axios.post('/offering/list', {
          "page": 1,
          "count": 100,
          "sort_field": "order",
          "sort_order": "asc",
          "is_output": 0,
        },
          {
            headers:
            {
              Authorization: `Bearer ${this.$store.state.token}`
            }
          })
        if (result.data.header.message == "Success") {
          this.service = result.data.data.list;
          return true
        }
      }
      catch (err) {
        console.error(err);
      }

    },
  },
}
</script>
 