<template>
	<div v-if="renderComponent">
		<div class="content-wrapper">
			<header class="wrapper bg-soft-primary">
				<nav class="navbar center-nav transparent position-absolute navbar-expand-lg navbar-dark">
					<div class="container flex-lg-row flex-nowrap align-items-center">
						<div class="navbar-brand w-100 navbar-logo">
							<router-link v-bind:to="'/home'" class="nav-link ">
								<img class="logo-dark" src="../assets/dist/img/logo.svg" alt="" />
								<img class="logo-light" src="../assets/dist/img/logo.svg" alt="" />
							</router-link>
						</div>
						<div class="navbar-other w-100 d-flex ms-auto">
							<ul class="navbar-nav flex-row align-items-center ms-auto" data-sm-skip="true">
								<li class="nav-item d-lg-none">
									<div class="navbar-hamburger"><button class="hamburger animate plain"
											data-toggle="offcanvas-nav">
										</button>
									</div>
								</li>
							</ul>
						</div>
						<div class="navbar-collapse offcanvas-nav">
							<div class="offcanvas-header d-lg-none d-xl-none">
								<button type="button" class="btn-close btn-close-white offcanvas-close offcanvas-nav-close"
									aria-label="Close"></button>
							</div>
							<ul class="navbar-nav">
								<li class="nav-item">
									<router-link v-bind:to="'/about'" class="nav-link ">
										關於王邵威律師
									</router-link>
								</li>
								<li class="nav-item">
									<router-link v-bind:to="'/service'" class="nav-link ">
										服務項目
									</router-link>
								</li>
								<li class="nav-item dropdown">
									<a class="nav-link dropdown-toggle">成功案例</a>
									<ul class="dropdown-menu">
										<li class="nav-item">
											<router-link v-bind:to="'/article?type_id=1&keyword='" class="dropdown-item ">
												<svg xmlns="http://www.w3.org/2000/svg" height="1em"
													viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
													<path
														d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z">
													</path>
												</svg>
												刑事案例
											</router-link>
										</li>
										<li class="nav-item">
											<router-link v-bind:to="'/article?type_id=2&keyword='" class="dropdown-item ">
												<svg xmlns="http://www.w3.org/2000/svg" height="1em"
													viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
													<path
														d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z">
													</path>
												</svg>
												民事案例
											</router-link>
										</li>
										<li class="nav-item">
											<router-link v-bind:to="'/article?type_id=3&keyword='" class="dropdown-item ">
												<svg xmlns="http://www.w3.org/2000/svg" height="1em"
													viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
													<path
														d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z">
													</path>
												</svg>
												家事案例
											</router-link>
										</li>
									</ul>
								</li>
								<li class="nav-item">
									<router-link v-bind:to="'/knowledge?keyword='" class="nav-link ">
										法律小知識
									</router-link>
								</li>
								<li class="nav-item">
									<router-link v-bind:to="'/contact'" class="nav-link ">
										預約諮詢
									</router-link>
								</li>
							</ul>
						</div>
					</div>
				</nav>
			</header>
		</div>
		<div v-if="$route.name != 'Home'" class="page-banner"
			v-bind:style="cssBgImg($store.state.websiteSetting.search.banner)">
		</div>
		<router-view :key="$route.fullPath">
		</router-view>
		<footer class="bg-dark text-inverse">
			<div class="container py-13 py-md-15">
				<div class="footer-flex">
					<div class="footer-logo">
						<img class="mb-4" src="../assets/dist/img/logo.svg" alt="" />
						<p class="mb-4"><i>Designed by <a target="blank" href="https://her-wis.com">herwis</a> © 2023</i>
						</p>
					</div>
					<div class="footer-nav">
						<ul class="list-unstyled mb-0">
							<li>
								<router-link v-bind:to="'/about'" class="nav-link ">
									關於王邵威律師
								</router-link>
							</li>
							<li>
								<router-link v-bind:to="'/service'" class="nav-link ">
									服務項目
								</router-link>
							</li>
							<li>
								<router-link v-bind:to="'/article?type_id=1&keyword='" class="nav-link ">
									成功案例
								</router-link>
							</li>
						</ul>
					</div>
					<div class="footer-nav">
						<ul class="list-unstyled mb-0">
							<li>
								<router-link v-bind:to="'/knowledge?keyword='" class="nav-link ">
									法律小知識
								</router-link>
							</li>
							<li>
								<router-link v-bind:to="'/contact'" class="nav-link ">
									預約諮詢
								</router-link>
							</li>
						</ul>
					</div>
					<div class="footer-info">
						<ul class="list-unstyled mb-0">
							<li>
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
									<path fill="white"
										d="M12 11.5A2.5 2.5 0 0 1 9.5 9A2.5 2.5 0 0 1 12 6.5A2.5 2.5 0 0 1 14.5 9a2.5 2.5 0 0 1-2.5 2.5M12 2a7 7 0 0 0-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 0 0-7-7Z" />
								</svg>
								<a target="blank"
									v-bind:href="'http://maps.google.com.tw/maps?q=' + $store.state.websiteSetting.search.address">
									{{ ' ' + $store.state.websiteSetting.search.address }}
								</a>
							</li>
							<li>
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
									<path fill="white"
										d="m16.556 12.906l-.455.453s-1.083 1.076-4.038-1.862s-1.872-4.014-1.872-4.014l.286-.286c.707-.702.774-1.83.157-2.654L9.374 2.86C8.61 1.84 7.135 1.705 6.26 2.575l-1.57 1.56c-.433.432-.723.99-.688 1.61c.09 1.587.808 5 4.812 8.982c4.247 4.222 8.232 4.39 9.861 4.238c.516-.048.964-.31 1.325-.67l1.42-1.412c.96-.953.69-2.588-.538-3.255l-1.91-1.039c-.806-.438-1.787-.309-2.417.317Z" />
								</svg>
								<a target="blank" v-bind:href="'tel:' + $store.state.websiteSetting.search.mobile">
									{{ ' ' + $store.state.websiteSetting.search.mobile }}
								</a>
							</li>
							<li>
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
									<path fill="white"
										d="M18 7.373V14.5a2.5 2.5 0 0 1-2.5 2.5h-11A2.5 2.5 0 0 1 2 14.5V7.373l7.746 4.558a.5.5 0 0 0 .508 0L18 7.373ZM15.5 4a2.5 2.5 0 0 1 2.485 2.223L10 10.92L2.015 6.223A2.5 2.5 0 0 1 4.5 4h11Z" />
								</svg>
								<a target="blank" v-bind:href="'mailto:' + $store.state.websiteSetting.search.email">
									{{ ' ' + $store.state.websiteSetting.search.email }}
								</a>
							</li>
						</ul>
					</div>
					<div class=" footer-media">
						<a target="blank" class="a-line-img"
							v-bind:href="'https://line.me/R/ti/p/' + $store.state.websiteSetting.search.line">
							<!-- <svg xmlns="http://www.w3.org/2000/svg" height="1em" style="padding: 2px;"
								viewBox="0 0 512 512">
								<path
									d="M311 196.8v81.3c0 2.1-1.6 3.7-3.7 3.7h-13c-1.3 0-2.4-.7-3-1.5l-37.3-50.3v48.2c0 2.1-1.6 3.7-3.7 3.7h-13c-2.1 0-3.7-1.6-3.7-3.7V196.9c0-2.1 1.6-3.7 3.7-3.7h12.9c1.1 0 2.4 .6 3 1.6l37.3 50.3V196.9c0-2.1 1.6-3.7 3.7-3.7h13c2.1-.1 3.8 1.6 3.8 3.5zm-93.7-3.7h-13c-2.1 0-3.7 1.6-3.7 3.7v81.3c0 2.1 1.6 3.7 3.7 3.7h13c2.1 0 3.7-1.6 3.7-3.7V196.8c0-1.9-1.6-3.7-3.7-3.7zm-31.4 68.1H150.3V196.8c0-2.1-1.6-3.7-3.7-3.7h-13c-2.1 0-3.7 1.6-3.7 3.7v81.3c0 1 .3 1.8 1 2.5c.7 .6 1.5 1 2.5 1h52.2c2.1 0 3.7-1.6 3.7-3.7v-13c0-1.9-1.6-3.7-3.5-3.7zm193.7-68.1H327.3c-1.9 0-3.7 1.6-3.7 3.7v81.3c0 1.9 1.6 3.7 3.7 3.7h52.2c2.1 0 3.7-1.6 3.7-3.7V265c0-2.1-1.6-3.7-3.7-3.7H344V247.7h35.5c2.1 0 3.7-1.6 3.7-3.7V230.9c0-2.1-1.6-3.7-3.7-3.7H344V213.5h35.5c2.1 0 3.7-1.6 3.7-3.7v-13c-.1-1.9-1.7-3.7-3.7-3.7zM512 93.4V419.4c-.1 51.2-42.1 92.7-93.4 92.6H92.6C41.4 511.9-.1 469.8 0 418.6V92.6C.1 41.4 42.2-.1 93.4 0H419.4c51.2 .1 92.7 42.1 92.6 93.4zM441.6 233.5c0-83.4-83.7-151.3-186.4-151.3s-186.4 67.9-186.4 151.3c0 74.7 66.3 137.4 155.9 149.3c21.8 4.7 19.3 12.7 14.4 42.1c-.8 4.7-3.8 18.4 16.1 10.1s107.3-63.2 146.5-108.2c27-29.7 39.9-59.8 39.9-93.1z" />
							</svg> -->
							<!-- <span>LINE</span> -->
						</a>
						<a target="blank" class="a-line-fb" v-bind:href="$store.state.websiteSetting.search.facebook">
							<!-- <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
								<path
									d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z" />
							</svg> -->
							<!-- <span>FACEBOOK</span> -->
						</a>
					</div>
					<!-- /column -->
				</div>
				<!--/.row -->
			</div>
			<div class="footer-fix">
				<a target="blank" v-bind:href="'tel:' + $store.state.websiteSetting.search.mobile">
					<div class="footer-fix-item">
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
							<path fill="white"
								d="m16.556 12.906l-.455.453s-1.083 1.076-4.038-1.862s-1.872-4.014-1.872-4.014l.286-.286c.707-.702.774-1.83.157-2.654L9.374 2.86C8.61 1.84 7.135 1.705 6.26 2.575l-1.57 1.56c-.433.432-.723.99-.688 1.61c.09 1.587.808 5 4.812 8.982c4.247 4.222 8.232 4.39 9.861 4.238c.516-.048.964-.31 1.325-.67l1.42-1.412c.96-.953.69-2.588-.538-3.255l-1.91-1.039c-.806-.438-1.787-.309-2.417.317Z">
							</path>
						</svg>
						<span>預約諮詢</span>
					</div>
				</a>
				<hr>
				<a target="blank" v-bind:href="'https://line.me/R/ti/p/' + $store.state.websiteSetting.search.line">
					<div class="footer-fix-item">
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" style="padding: 4px 2px 0px 2px;"
							viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
							<path style="
          fill: white;" d="M311 196.8v81.3c0 2.1-1.6 3.7-3.7 3.7h-13c-1.3 0-2.4-.7-3-1.5l-37.3-50.3v48.2c0 2.1-1.6 3.7-3.7 3.7h-13c-2.1 0-3.7-1.6-3.7-3.7V196.9c0-2.1 1.6-3.7 3.7-3.7h12.9c1.1 0 2.4 .6 3 1.6l37.3 50.3V196.9c0-2.1 1.6-3.7 3.7-3.7h13c2.1-.1 3.8 1.6 3.8 3.5zm-93.7-3.7h-13c-2.1 0-3.7 1.6-3.7 3.7v81.3c0 2.1 1.6 3.7 3.7 3.7h13c2.1 0 3.7-1.6 3.7-3.7V196.8c0-1.9-1.6-3.7-3.7-3.7zm-31.4 68.1H150.3V196.8c0-2.1-1.6-3.7-3.7-3.7h-13c-2.1 0-3.7 1.6-3.7 3.7v81.3c0 1 .3 1.8 1 2.5c.7 .6 1.5 1 2.5 1h52.2c2.1 0 3.7-1.6 3.7-3.7v-13c0-1.9-1.6-3.7-3.5-3.7zm193.7-68.1H327.3c-1.9 0-3.7 1.6-3.7 3.7v81.3c0 1.9 1.6 3.7 3.7 3.7h52.2c2.1 0 3.7-1.6 3.7-3.7V265c0-2.1-1.6-3.7-3.7-3.7H344V247.7h35.5c2.1 0 3.7-1.6 3.7-3.7V230.9c0-2.1-1.6-3.7-3.7-3.7H344V213.5h35.5c2.1 0 3.7-1.6 3.7-3.7v-13c-.1-1.9-1.7-3.7-3.7-3.7zM512 93.4V419.4c-.1 51.2-42.1 92.7-93.4 92.6H92.6C41.4 511.9-.1 469.8 0 418.6V92.6C.1 41.4 42.2-.1 93.4 0H419.4c51.2 .1 92.7 42.1 92.6 93.4zM441.6 233.5c0-83.4-83.7-151.3-186.4-151.3s-186.4 67.9-186.4 151.3c0 74.7 66.3 137.4 155.9 149.3c21.8 4.7 19.3 12.7 14.4 42.1c-.8 4.7-3.8 18.4 16.1 10.1s107.3-63.2 146.5-108.2c27-29.7 39.9-59.8 39.9-93.1z">
							</path>
						</svg>
						<span>LINE</span>
					</div>
				</a>
				<hr><a target="blank">
					<div class="footer-fix-item toTop">
						<div style="font-size: 30px;line-height: 10px;margin-top: 15px;">
							<svg xmlns="http://www.w3.org/2000/svg" height=".7em"
								viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
								<path
									d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z" />
							</svg>
						</div>
						<span>Top</span>
					</div>
				</a>
			</div>
			<!-- /.container -->
		</footer>
		<div class="progress-wrap">
			<svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
				<path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
			</svg>
		</div>
	</div>
</template>

<script>
// import AOS from '../assets/dist/js/aos'
export default {
	name: 'Layout',
	computed: {
	},
	watch: {
	},
	data() {
		return {
			renderComponent: true,
		}
	},
	methods: {
		cssBgImg: function (url) {
			return {
				'background-image': 'url("data:image/jpeg;base64, ' + url + '")'
			}
		},
	},
	mounted() {
	}
}
</script>   