<template>
  <div>
    <section class="page-title">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <ul class="bread">
              <li class="first">
                <router-link v-bind:to="'/home'"> 首頁
                </router-link>
                /&nbsp;
              </li>
              <li>
                <h1>
                  <router-link v-bind:to="'/contact'">
                    預約諮詢
                  </router-link>
                </h1>
              </li>
            </ul>
          </div>
          <div class="col-md-12">
            <div class="page-title-text">
              <hr />
              <h2>預約諮詢</h2>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="home-contact">
      <div class="container">
        <!-- <div class="contact-outline-text">Consultation</div> -->
        <form class="row row-mid">
          <div class="col-xl-5 bg-contact-01">
            <div class="contact-left">
              <h3>
                諮詢須知
              </h3>
              <p v-html="$store.state.websiteSetting.search.consultation_form_content.replaceAll('\n', '<br />')">
              </p>
            </div>
          </div>
          <div class="col-xl-7 bg-contact-02 mt-5">
            <p v-html="$store.state.websiteSetting.search.consultation_form_info.replaceAll('\n', '<br />')">
            </p>
            <div class="contact-form">
              <label>姓名<span class="required-star">*</span></label>
              <div>
                <input v-model="form.name" type="text" class="form-control">
              </div>
              <label>稱謂<span class="required-star">*</span></label>
              <div style="display: flex;gap: 25px;">
                <div class="form-check mb-0">
                  <input v-model="form.gender" class="form-check-input" type="radio" name="gender" id="gender1"
                    value="Male" checked>
                  <label class="form-check-label" for="gender1">
                    先生
                  </label>
                </div>
                <div class="form-check  mb-0">
                  <input v-model="form.gender" class="form-check-input" type="radio" name="gender" id="gender2"
                    value="Female">
                  <label class="form-check-label" for="gender2">
                    小姐
                  </label>
                </div>
              </div>
              <label>電話<span class="required-star">*</span></label>
              <div>
                <input v-model="form.mobile" type="text" class="form-control">
              </div>
              <label>諮詢問題<span class="required-star">*</span></label>
              <div>
                <textarea v-model="form.question" class="form-control" rows="6"></textarea>
              </div>
              <!-- <label>驗證碼<span class="required-star">*</span></label>
              <div>
                <input type="text" class="form-control">
              </div> -->
            </div>
            <p class="errMsg">{{ msg }}</p>
            <div style="text-align: center;margin: 1em 0 ">
              <button class="btn-fill" @click="sendForm($event) && recaptcha(form)">送出</button>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { useReCaptcha } from 'vue-recaptcha-v3'
import axios from 'axios';
import router from '../../router'
import store from '../../store'

export default {
  setup() {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

    async function createConsultation(form) {
      try {
        let result = await axios.post('/consultation-form/create',
          form
          ,
          {
            headers:
            {
              Authorization: `Bearer ${store.state.token}`
            }
          })

        store.commit('SetCreateContent', result)
        if (result.data.header.message == "Success") {
          alert('諮詢表單發送成功！');
          router.push({ name: 'Home' })
          return true
        } else {
          alert('送出失敗')
        }
      }
      catch (err) {
        console.error(err);
      }
    }

    // submit 回傳一組 token，並把 token 傳給後端驗證 
    const recaptcha = async (form) => {
      await recaptchaLoaded();
      const token = await executeRecaptcha('login');
      form["g-recaptcha-response"] = token;
      createConsultation(form);
    }
    return {
      recaptcha,
    }
  },
  name: 'Content',
  components: {
  },
  computed: {
    ...mapGetters(['InitPage'])
  },
  watch: {
  },
  data() {
    return {
      consultation: null,
      form: {
        name: null,
        gender: 'Male',
        mobile: null,
        question: null,
        is_processed: 0,
        token: null
      },
      msg: ""
    }
  },
  created() {
    this.$InitPage();
  },
  activated() {
  },
  methods: {
    sendForm: function (e) {
      e.preventDefault();
      // var self = this;
      this.msg = '';
      if (!this.form.name) {
        this.msg = '姓名 為必填'
        return
      }
      if (!this.form.gender) {
        this.msg = '稱謂 為必填'
        return
      }
      if (!this.form.mobile) {
        this.msg = '電話 為必填'
        return
      }
      if (!this.form.question) {
        this.msg = '諮詢問題 為必填'
        return
      }

      return true;
      // this.createConsultation();
    }
  },

}
</script>
  